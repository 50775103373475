import {
    ACCESS_TOKEN_STORAGE,
    REFRESH_TOKEN_STORAGE,
} from "../constants/Storage";

class StorageService {
    static get(param, _defValue) {
        const data = JSON.parse(localStorage.getItem(param));

        if (_defValue === undefined) {
            return data;
        }

        return data || _defValue;
    }

    static set(key, data) {
        return localStorage.setItem(key, JSON.stringify(data));
    }

    static getAccessToken() {
        return JSON.parse(localStorage.getItem(ACCESS_TOKEN_STORAGE));
    }

    static getRefreshToken() {
        return JSON.parse(localStorage.getItem(REFRESH_TOKEN_STORAGE));
    }

    static clear() {
        return localStorage.clear();
    }
}

export default StorageService;
