import { lazy, useMemo } from 'react';
import { withSuspense } from './utils/withSuspense';
import { ROLE_MUNICIPALITY, ROLE_TENANT, ROLE_OWNER } from './constants/Roles';
import { ROLE_STORAGE } from './constants/Storage';
import StorageService from './services/StorageService';

const DefaultRouter = withSuspense(lazy(() => import('./routes/default')));
const TenantRouter = withSuspense(lazy(() => import('./routes/tenant')));
const OwnerRouter = withSuspense(lazy(() => import('./routes/owner')));
const MunicipalityRouter = withSuspense(lazy(() => import('./routes/municipality')));

interface IUser {
	// role: string[];
	// username: string;
	// email: string;
	exp: string;
	iat: string;
	iss: string;
	sub: string;
	role: string[];
}

const App = () => {
	const auth = {
	  isAuthenticated: false,
	  user:{
	    access_token: "",
			refresh_token: "",
			role: ""
	  }
	};
	const access_token = StorageService.getAccessToken();
	const refresh_token = StorageService.getRefreshToken();
	if(access_token && refresh_token){
		auth.isAuthenticated = true;
		auth.user.access_token = access_token;
		auth.user.refresh_token = refresh_token;
		auth.user.role = StorageService.get(ROLE_STORAGE);
	}

	const router = useMemo(() => {
		if (!auth) return <DefaultRouter />
		if (!auth.isAuthenticated) return <DefaultRouter />
		if (!auth.user?.access_token) return <DefaultRouter />

		if (!auth.user.role) return <DefaultRouter />
		if (auth.user.role === ROLE_OWNER) return <OwnerRouter />
		if (auth.user.role === ROLE_TENANT) return <TenantRouter />
		if (auth.user.role === ROLE_MUNICIPALITY) return <MunicipalityRouter />
		return <DefaultRouter />;
	}, [auth])

	return router;
}

export default App;
