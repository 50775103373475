import { type ComponentType, Suspense, type SuspenseProps } from 'react';
import { Loader } from 'rsuite';

/**
 * Wraps lazy-loaded component with React.Suspense and optional fallback.
 * Prevents page flickering, when used with react-router-dom.
 * @param WrappedComponent The component to wrap.
 * @param fallback The component to render while loading.
 *
 * @example
 * const ApiClients = withSuspense(lazy(() => import('./ApiClients')));
 */
export function withSuspense<P extends object>(WrappedComponent: ComponentType<P>, fallback: SuspenseProps['fallback'] = null): React.FC<P> {
	function ComponentWithSuspense(props: P) {
		return (
			<Suspense fallback={fallback ?? <Loader center />}>
				<WrappedComponent {...props} />
			</Suspense>
		);
	}

	return ComponentWithSuspense;
}
